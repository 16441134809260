import { Suspense, useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ViewPdf from "../../pages/ViewPdf";

const DownloadAction = ({ item }) => {
  const [viewCertificate, setViewCertificate] = useState(false)

  if (!viewCertificate) {
    return (
      <div className="cursor-pointer" onClick={() => setViewCertificate(true)}>
        <i class="fa-solid fa-eye"></i>
      </div>
    )
  }

  return (
    <Suspense fallback={() => <></>}>
      <PDFDownloadLink
        document={<ViewPdf course={item} />}
        fileName='certificate.pdf'
      >
        {({ loading }) =>
          loading ? (
            <i className='fad fa-spinner-third animate-spin'></i>
          ) : (
            <div className='cursor-pointer'>
              <i className="fa fa-download" aria-hidden="true"></i>
            </div>
          )
        }
      </PDFDownloadLink>
    </Suspense>
  );
};

export default DownloadAction;
